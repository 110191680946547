<script setup lang="ts">
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import type { Toast } from '~/types/toast';

// Components
import ExploreOffConsumptionTab from '~/app-modules/explore/components/tabs/OffConsumptionTab.vue';
import ExploreConsumptionTab from '~/app-modules/explore/components/tabs/ConsumptionTab.vue';
import ExploreIpeTab from '~/app-modules/explore/components/tabs/EpiTab.vue';
import { useI18n } from 'vue-i18n';

dayjs.extend(isBetween);

// Composables
const router = useRouter();
const route = useRoute();
const { t } = useI18n();

// Store
const appService = appStore();

// Data
const modalAreaSelector = ref(false);
const state = reactive({
  selectedTab: 0,
  isIncidentSidebarOpen: false,
  isIncidentCreationSidebarOpen: false,
  toast: inject('toast') as Toast,
});

// Methods
const setDate = (event: { start: Date; end: Date }) => {
  appStore().setExploreDateRange({ start: event.start, end: event.end });
};

onMounted(() => {
  appStore().setMeter(parseInt(router.currentRoute.value.params.id as string));
});

// Computed
const tabs = computed(() => {
  const tabs: { id: number; label: string }[] = [
    {
      id: 0,
      label: t('global.consumption'),
    },
  ];

  if (!isOffProdHidden.value) {
    tabs.push({
      id: 1,
      label: t('analytics.off_production_analysis'),
    });
  }

  if (!isIPEHidden.value) {
    tabs.push({
      id: 2,
      label: t('analytics.analysis_EPI'),
    });
  }

  return tabs;
});

const meter = computed(() => {
  const meterId = appStore().getMeter || parseInt(route.params.id as string);
  return meterStore().getMeter(meterId);
});

const isOffProdHidden = computed(() => {
  return meter.value?.meter_type.name === 'grdf';
});

const isIPEHidden = computed(() => {
  return meter.value?.area.production_reporting_frequency === null;
});

const formatDateForDateSelector = computed(() => {
  return {
    start: dayjs(appService.getExploreDateRange.start).toDate(),
    end: dayjs(appService.getExploreDateRange.end).toDate(),
  };
});

const dateRange = computed(() => {
  return {
    start: dayjs(appService.getExploreDateRange.start).format('YYYY-MM-DD'),
    end: dayjs(appService.getExploreDateRange.end).format('YYYY-MM-DD'),
  };
});
</script>

<template>
  <div class="flex flex-col w-full h-full bg-gray-50">
    <ui-header :title="meter?.area.name + ' - ' + t(`global.energy_type.${meter?.meter_type.energy_type}`)">
      <template #actions>
        <ui-range-date-selector :range="formatDateForDateSelector" @update:range="setDate">
          {{ t('global.period') }}
        </ui-range-date-selector>
        <menu-area-selector v-model="modalAreaSelector" />
      </template>
    </ui-header>

    <div class="flex w-full h-full overflow-hidden">
      <div class="flex flex-1 justify-start flex-col gap-4 p-4 overflow-y-auto">
        <ui-card>
          <ui-tab v-model="state.selectedTab" :list-tabs="tabs" />
        </ui-card>
        <explore-consumption-tab
          v-if="state.selectedTab === 0"
          :date-range="dateRange"
          :data-type="meter?.meter_type.energy_type"
          @update-date-range="dateRange = $event"
        />
        <explore-off-consumption-tab v-if="state.selectedTab === 1" :date-range="dateRange" />
        <explore-ipe-tab v-if="state.selectedTab === 2" :date-range="dateRange" />
      </div>
    </div>
  </div>
</template>
