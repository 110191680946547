import { useI18n } from 'vue-i18n';
import { useConsumption } from './useConsumption';
import type { Consumption } from '~/types/API/consumption';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
interface Data {
  id: number;
  name: string;
  consumption: Consumption[];
}

dayjs.extend(isSameOrAfter);

export const useExplore = (options: { dateRange: { start: string; end: string } }) => {
  // Composables
  const route = useRoute();
  const { t } = useI18n();
  const { getConsumption } = useConsumption();
  const dateRange = ref<{ start: string; end: string }>(options.dateRange);

  // Variables
  const loading = ref<boolean>(false);
  const data = ref<Data[]>([]);

  // Computed
  const aggregationLevel = computed(() => appStore().getAggregationLevel);
  const groupChild = computed(() => appStore().getGroupChild);
  const meterId = computed(() => parseInt(route.params.id as string));
  const currentAreaId = computed(() => meterStore().getMeter(meterId.value)?.area.id);
  const currentArea = computed(() => (currentAreaId.value ? areaStore().getArea(currentAreaId.value) : undefined));
  const currentMeter = computed(() => meterStore().getMeter(meterId.value));
  const timestamps = computed(() => data.value?.[0]?.consumption.map((consumption) => consumption.date) ?? []);

  const childMeters = computed(() =>
    currentArea
      ? currentArea.value?.children
          .map((childArea) => {
            const meters = areaStore().getArea(childArea.id)?.meters;
            if (!meters) return [];

            return meters
              .filter((meter) => {
                const currentMeterEnergyType = currentMeter.value?.meter_type.energy_type;
                if (currentMeterEnergyType !== meter.meter_type.energy_type) return false;
                return true;
              })
              .map((meter) => {
                return {
                  id: meter.id,
                  name: childArea.name,
                };
              });
          })
          .flat()
      : undefined,
  );

  const datasets = computed(() => {
    const newDataSets = data.value?.map((element) => ({
      data: element.consumption.map((consumption) => consumption.consumption_kwh),
      label: element.name,
      backgroundColor: data.value.length > 1 ? null : '#16a34a',
      borderRadius: 2,
      hoverBackgroundColor: '#78716c',
      minBarLength: groupChild.value === 'meter' ? 4 : 0,
    }));

    return newDataSets ?? [{ data: [], label: '', backgroundColor: '' }];
  });

  // Methods
  async function getConsumptions() {
    loading.value = true;

    if (!currentMeter.value) {
      loading.value = false;
      return;
    }

    const consumptions = await getConsumption({
      meterId: currentMeter.value?.id ?? 0,
      ...dateRange.value,
      aggregationLevel: aggregationLevel.value,
    });

    const filteredConsumptions = consumptions.filter((consumption, index) =>
      filterConsumptions(aggregationLevel.value, dateRange.value, consumptions, index),
    );

    const parentMeterConsumption = {
      id: currentMeter.value.id,
      name: currentMeter.value.area.name,
      pourcent: [],
      consumption: filteredConsumptions,
    };

    if (childMeters.value && childMeters.value?.length > 0 && groupChild.value !== 'meter') {
      const childMetersCOnsumption = await Promise.all(
        childMeters.value.map(async (meter) => {
          const childConsumption = await getConsumption({
            meterId: meter.id,
            ...dateRange.value,
            aggregationLevel: aggregationLevel.value,
          });

          const pourcentage = parentMeterConsumption.consumption.map(
            (parentConsumption, index) => (childConsumption[index].consumption_kwh / parentConsumption.consumption_kwh) * 100,
          );

          return {
            ...meter,
            pourcent: pourcentage,
            consumption: childConsumption.filter((consumption, index) =>
              filterConsumptions(aggregationLevel.value, dateRange.value, consumptions, index),
            ),
          };
        }),
      );

      parentMeterConsumption.consumption.forEach((consumption, index) => {
        let newConsumption = consumption.consumption_kwh;
        let newCost = consumption.cost;
        let newPourcent = 100;

        childMetersCOnsumption.forEach((child) => {
          newConsumption -= child.consumption[index].consumption_kwh;
          newCost -= child.consumption[index].cost;
          newPourcent -= child.pourcent[index];
        });

        consumption.consumption_kwh = Math.sign(newConsumption) === -1 ? 0 : newConsumption;
        consumption.cost = Math.sign(newCost) === -1 ? 0 : newCost;
        parentMeterConsumption.pourcent.push(newPourcent);
      });

      parentMeterConsumption.name = t('global.other');
      parentMeterConsumption.id = 0;

      data.value = [...childMetersCOnsumption, parentMeterConsumption];

      loading.value = false;
      return;
    }

    data.value = [parentMeterConsumption];

    loading.value = false;
    return;
  }

  getConsumptions();

  function reload(newDateRange: { start: string; end: string }) {
    dateRange.value = newDateRange;
    getConsumptions();
  }

  watch(
    () => meterId.value,
    () => {
      getConsumptions();
    },
  );

  return {
    timestamps,
    childMeters,
    data,
    datasets,
    aggregationLevel,
    groupChild,
    loading,
    reload,
  };
};

const filterConsumptions = (
  aggregationLevel: string,
  dateRange: { start: string; end: string },
  consumptions: Consumption[],
  index: number,
) => {
  // Month
  if (aggregationLevel === 'month' && index === 0) {
    return dayjs(dateRange.start).isSame(dayjs(dateRange.start).startOf('month'), 'day');
  } else if (aggregationLevel === 'month' && index === consumptions.length - 1) {
    return dayjs(dateRange.end).isSameOrAfter(dayjs(dateRange.end).endOf('month'), 'day');
  }

  // Week
  if (aggregationLevel === 'week' && index === 0) {
    return dayjs(dateRange.start).isSame(dayjs(dateRange.start).startOf('week'), 'day');
  } else if (aggregationLevel === 'week' && index === consumptions.length - 1) {
    return dayjs(dateRange.end).isSameOrAfter(dayjs(consumptions[index].date).add(6, 'day'), 'day');
  }

  return true;
};
