<script setup lang="ts">
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { unitsStore } from '~/stores/units';
import { useI18n } from 'vue-i18n';
import type { Area } from '~/types/area';

// Composables
const { t } = useI18n();
const route = useRoute();

// Stores
const meterService = meterStore();

// Props & emits
const modal = defineModel<boolean>();

// Data
const submiting = ref(false);

// Form
const schema = yup.object({
  production_unit_id: yup.string().required(t('form.the_field_production_unit_is_required')),
  production_reporting_frequency: yup.string().required(t('form.the_production_frequency_is_required')),
});
const { handleSubmit, setValues } = useForm<Partial<Area>>({
  validationSchema: schema,
});

// Methods
const submit = handleSubmit(async (values) => {
  submiting.value = true;
  const meter = meterStore().getMeter(parseInt(route.params.id as string));
  if (!meter) {
    // TODO: add toast in this case
    return;
  }
  await areaStore().updateArea({ areaId: meter.area.id, form: values });
  submiting.value = false;
  modal.value = false;
});

// Computed
const units = computed(() => {
  const unitsList = unitsStore().units.list.filter((unit) => unit.type === 'prod');
  return unitsList.map((unit) => ({
    key: unit.id,
    label: unit.symbol,
  }));
});
const productionTime = computed(() => {
  return [
    { key: 'week', label: t('global.week') },
    { key: 'month', label: t('global.month') },
    { key: 'custom', label: t('global.custom') },
  ];
});

watch(modal, (value) => {
  if (value) {
    const meter = meterStore().getMeter(parseInt(route.params.id as string));
    if (!meter) return;
    const area = areaStore().getArea(meter.area.id);
    if (!area) return;
    setValues({
      production_unit_id: area.production_unit_id,
      production_reporting_frequency: area.production_reporting_frequency,
    });
  }
});
</script>
<template>
  <ui-modal
    v-model="modal"
    width="600"
    :title="
      t('EPI.configure_production_reporting', {
        areaName: meterService.getMeter(parseInt(route.params.id as string))?.area.name,
      })
    "
  >
    <div class="grid gap-4">
      <ui-form-input-select
        name="production_reporting_frequency"
        :label="$t('EPI.production_reporting_frequency')"
        :placeholder="$t('EPI.choose_production_frequency')"
        panel-position="relative"
        :items="productionTime"
      />
      <ui-form-input-select
        name="production_unit_id"
        :label="$t('EPI.production_unit')"
        :placeholder="$t('EPI.choose_production_unit')"
        panel-position="relative"
        :items="units"
      />
    </div>

    <template #footer>
      <ui-button :loading="submiting" @click="submit()">
        {{ $t('global.save') }}
      </ui-button>
    </template>
  </ui-modal>
</template>
