<script setup lang="ts">
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import EpiOFConfigModal from '../modal/EpiOFConfigModal.vue';
import type { ProductsQueryQuery } from '~/types/graphql/graphql';
import type { Header } from '~/components/ui/data/DataTable.vue';
import type { TableAction } from '~/components/ui/data/DataTableActions.vue';

import ManageOfModal from '../modal/ManageOfModal.vue';

// Types
type product = NonNullable<ProductsQueryQuery['products_by_pk']>;

// Composables
const { t } = useI18n();
const route = useRoute();
const { getNewEPIType } = useEPI();
const service = epiStore();

// Props and emit
defineEmits<{
  (e: 'update'): void;
}>();

// Data
const seletedUnit = ref<'epi' | 'cost' | 'co2'>('epi');
const configModal = ref<boolean>(false);
const modalOfId = ref<number | null>(null);
const manageOfModalStatus = ref(false);

// Stores
const areaService = areaStore();

// Computed
const areaId = computed(() => {
  return meterStore().getMeter(parseInt(route.params.id as string))?.area.id ?? null;
});
const unit = computed(() => areaService.getAreaByMeterId(appStore().getMeter)?.unit?.symbol ?? 'kg');
const items = computed(() => getNewEPIType());

const actions = computed((): TableAction[] => {
  return [
    {
      key: 'edit',
      label: t('global.edit'),
      icon: 'Pen',
      iconStokeWidth: 2,
    },
  ];
});

const areaIsConfigured = computed(() => {
  if (areaId.value === null) return false;
  const area = areaStore().getArea(areaId.value);
  return area?.production_reporting_frequency && area?.production_unit_id;
});

const headers: Header[] = [
  { label: t('global.date'), key: 'start' },
  { label: t('global.hour'), key: 'hour' },
  { label: t('global.duration'), key: 'duration' },
  { label: t('global.OF'), key: 'client_fabrication_order_id' },
  { label: t('global.EPI'), key: 'epi' },
  { label: t('global.product', 2), key: 'fabrication_order_product_names' },
  { label: t('global.quantity'), key: 'quantity' },
  { label: t('analytics.avg_power'), key: 'avg_power' },
  { label: t('global.consumption'), key: 'consumption' },
  { label: '', key: 'action', className: 'center' },
];

// Methods
const getEPI = (item: product['fabrication_order_products'][0]['fabrication_order']) => {
  if (seletedUnit.value === 'co2') {
    const co2 = useCO2().getCo2byKWhByMeterId(parseInt(route.params.id as string));

    const value = (item?.fabrication_order_epis[0]?.epi ?? 0) * co2;
    return `${value.toFixed(2)} gCO2/${item?.area?.unit?.symbol ?? 'kg'}`;
  } else {
    const fabrication_order_epi = item?.fabrication_order_epis;
    const value = fabrication_order_epi
      ? (fabrication_order_epi.length > 0 ? fabrication_order_epi[0][seletedUnit.value] : 0).toFixed(2)
      : 0;
    return `
      ${value}
      ${getNewEPIType().find((el) => el.key === seletedUnit.value)?.label}
      /
      ${item?.area?.unit?.symbol ?? 'kg'}
    `;
  }
};

const clickItem = (event: string, item: product['fabrication_order_products'][0]['fabrication_order']) => {
  if (event === 'edit') {
    modalOfId.value = item.id;
    manageOfModalStatus.value = true;
  }
};
</script>

<template>
  <ui-card :title="$t('analytics.fabrication_orders')">
    <template #header>
      <ui-button v-if="areaIsConfigured" left-icon-class="!w-4" left-icon="Pencil" color="secondary" @click="configModal = true">
        {{ $t('analytics.update_units') }}
      </ui-button>
      <ui-button v-if="areaIsConfigured" left-icon-class="!w-4" left-icon="Pencil" @click="manageOfModalStatus = true">
        {{ $t('epi.add_fabrication_order') }}
      </ui-button>
    </template>
    <ui-info
      v-if="!areaIsConfigured && !service.loading"
      class="mb-4"
      :message="$t('IPE.no_configure_yet')"
      :link="t('IPE.configure')"
      @click-link="configModal = true"
    />
    <ui-data-table :loading="service.loading" :items="service.fabricationOrdersFiltered" :headers="headers">
      <template #header-epi>
        <ui-dropdown v-model="seletedUnit" size="sm" :items="items" placeholder="IPE" />
      </template>
      <template #item-start="{ item }">
        {{ dayjs(item.start).format('DD-MM-YYYY') }}
      </template>
      <template #item-hour="{ item }">
        {{ dayjs(item.start).format('HH:mm') }}
      </template>
      <template #item-duration="{ item }"> {{ dayjs(item.end).diff(item.start, 'hour') }} h </template>
      <template #item-quantity="{ item }"> {{ item.quantity.toFixed(0) }} {{ unit }} </template>
      <template #item-consumption="{ item }">
        <template v-if="item.has_up_to_date_epi"> {{ item.fabrication_order_epis[0].consumption_kwh.toFixed(0) }} kWh </template>
      </template>
      <template #item-avg_power="{ item }">
        <template v-if="item.has_up_to_date_epi">
          {{
            (item.fabrication_order_epis[0]?.consumption_kwh
              ? (item.fabrication_order_epis[0]?.consumption_kwh ?? 0) / dayjs(item.end).diff(item.start, 'hour', true)
              : 0
            ).toFixed(0)
          }}
          kW
        </template>
      </template>
      <template #item-epi="{ item }">
        <ui-badge v-if="!item.has_up_to_date_epi" color="orange">
          {{ $t('global.calculates_in_progress') }}
        </ui-badge>
        <ui-badge v-else-if="item.fabrication_order_epis[0].consumption_kwh === 0" color="red">
          {{ $t('global.no_data') }}
        </ui-badge>
        <div v-else>
          {{ getEPI(item) }}
        </div>
      </template>
      <template #item-fabrication_order_product_names="{ item }">
        <div v-if="item.fabrication_order_products?.length !== 0" class="flex flex-col gap-2">
          <div v-for="product in item.fabrication_order_products" :key="`product-${product.product.id}`">
            <ui-badge color="blue">
              {{ product.product?.client_product_id }}
            </ui-badge>
          </div>
        </div>
        <span v-else>--</span>
      </template>
      <template #item-action="{ item }">
        <ui-data-table-actions :items="actions" @click-item="clickItem($event, item)" />
      </template>
    </ui-data-table>
  </ui-card>

  <!-- Modals -->
  <manage-of-modal v-model="manageOfModalStatus" :of-id="modalOfId" @update="service.fetchFabricationOrders()" />
  <EpiOFConfigModal v-model="configModal" />
</template>
