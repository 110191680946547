<script setup lang="ts">
// Components
import AnalyticsIpeGraph from '@/app-modules/explore/components/graphs/EpiGraph.vue';
import AnalyticsIpeTable from '@/app-modules/explore/components/tables/IpeTable.vue';

// Composables
const { proxy } = useScriptSegment();
const epiService = epiStore();

const meter = meterStore().getMeter(appStore().getMeter);

// Lifecycle
onMounted(async () => {
  await epiService.fetchFabricationOrders();
  proxy.page('IPETab');
});

watch(
  () => [appStore().getExploreDateRange, appStore().getMeter],
  async () => {
    await epiService.fetchFabricationOrders();
  },
);
</script>

<template>
  <div class="flex flex-col gap-4">
    <ui-card v-if="meter?.meter_type.energy_type !== 'Gas'">
      <ui-search-bar v-model="epiService.search" :placeholder="$t('global.find_product')" />
    </ui-card>
    <analytics-ipe-graph v-if="meter?.meter_type.energy_type !== 'Gas'" />
    <analytics-ipe-table v-if="meter?.meter_type.energy_type !== 'Gas'" />

    <!-- Warning no IPE -->
    <ui-warning v-if="meter?.meter_type.energy_type === 'Gas'" :title="$t('epi.no_ipe_gas_title')" :message="$t('epi.no_ipe_gas')" />
  </div>
</template>
