<script lang="ts" setup>
import { provideApolloClient } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { FABRICATION_ORDER_INSERT, FABRICATION_ORDER_UPDATE } from '~/graphql/fabrication_orders';

dayjs.extend(advancedFormat);

// Composables & plugins
const { apolloClient } = useApollo();
const { t } = useI18n();
const { showToast } = toastStore();

provideApolloClient(apolloClient);

// Props & emits
const emits = defineEmits(['update']);
const modal = defineModel<boolean>();
const props = defineProps<{
  ofId: number | null;
}>();

// Stores
const { getMeter } = appStore();

// Data
const isSubmitting = ref(false);

// Form
const schema = yup.object().shape({
  reportingFrequency: yup.string(),
  start: yup.string().when('reportingFrequency', ([reportingFrequency], schema) => {
    return reportingFrequency === 'dateTime' ? schema.required(t('epi.form.start_date_is_required')) : schema.notRequired();
  }),
  end: yup.string().when('reportingFrequency', ([reportingFrequency], schema) => {
    return reportingFrequency === 'dateTime' ? schema.required(t('epi.form.end_date_is_required')) : schema.notRequired();
  }),
  week: yup.string().when('reportingFrequency', ([reportingFrequency], schema) => {
    return reportingFrequency === 'week' ? schema.required(t('epi.form.week_is_required')) : schema.notRequired();
  }),
  month: yup.string().when('reportingFrequency', ([reportingFrequency], schema) => {
    return reportingFrequency === 'month' ? schema.required(t('epi.form.month_is_required')) : schema.notRequired();
  }),
  quantity: yup.number().required(t('epi.form.quantity_is_required')).typeError(t('epi.form.quantity_is_required')),
});
const { handleSubmit, values, setValues } = useForm<{
  reportingFrequency: string;
  start: string;
  end: string;
  quantity: number;
  week: string;
  month: string;
}>({
  validationSchema: schema,
});

// Computed
const title = computed(() => (props.ofId ? t('epi.edit_fabrication_order') : t('epi.add_fabrication_order')));
const unit = computed(() => areaStore().getAreaByMeterId(getMeter)?.unit.symbol);
const reportingFrequency = computed(() => {
  const frequency = areaStore().getAreaByMeterId(getMeter).production_reporting_frequency;
  if (['week', 'month'].includes(frequency)) {
    return frequency;
  } else {
    return 'dateTime';
  }
});

const getWeeksAsOptions = computed(() => {
  const area = areaStore().getAreaByMeterId(getMeter);
  const weeks = [];
  const day_id = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  }[area.site.production_start_weekday!];
  const currentWeek = dayjs().day(day_id!).startOf('day');

  for (let i = 0; i < 104; i++) {
    // 2 years historical data
    const startOfWeek = currentWeek.subtract(i, 'week');

    weeks.push({
      label: `${t('global.week')} ${startOfWeek.format('ww - YYYY')}`,
      key: startOfWeek.format('YYYY-MM-DDTHH:mm'),
    });
  }

  return weeks;
});

const getMonthsAsOptions = computed(() => {
  const months = [];
  const currentMonth = dayjs().startOf('month');

  for (let i = 0; i < 24; i++) {
    // 2 years historical data
    const startOfMonth = currentMonth.subtract(i, 'month');

    months.push({
      label: `${startOfMonth.endOf('month').format('MMMM YYYY')}`,
      key: startOfMonth.format('YYYY-MM-DDTHH:mm'),
    });
  }
  return months;
});

// Methods
const submit = handleSubmit(async (values) => {
  let start = dayjs(values.start).toDate();
  let end = dayjs(values.end).toDate();

  if (reportingFrequency.value === 'month') {
    start = dayjs(values.month).toDate();
    end = dayjs(values.month).endOf('month').toDate();
  } else if (reportingFrequency.value === 'week') {
    start = dayjs(start).startOf('week').toDate();
    end = dayjs(end).endOf('week').toDate();
  }

  if (props.ofId) {
    await apolloClient.mutate({
      mutation: FABRICATION_ORDER_UPDATE,
      variables: {
        fabrication_order: {
          start,
          end,
          quantity: values.quantity,
        },
        id: props.ofId,
      },
    });
    showToast({ type: 'success', title: t('global.success'), message: t('epi.fabrication_order_updated') });
  } else {
    await apolloClient.mutate({
      mutation: FABRICATION_ORDER_INSERT,
      variables: {
        fabrication_order: {
          start,
          end,
          quantity: values.quantity,
          area_id: areaStore().getAreaByMeterId(getMeter).id,
        },
      },
    });
    showToast({ type: 'success', title: t('global.success'), message: t('epi.fabrication_order_created') });
  }
  emits('update');
  isSubmitting.value = false;
  modal.value = false;
});

watch(
  () => props.ofId,
  () => {
    if (props.ofId) {
      const fabricationOrder = epiStore().getFabricationOrder(props.ofId);
      if (fabricationOrder) {
        setValues({
          reportingFrequency: reportingFrequency.value,
          start: fabricationOrder.start,
          end: fabricationOrder.end,
          quantity: fabricationOrder.quantity,
        });
      }
    }
  },
);

onMounted(() => {
  setValues({
    reportingFrequency: reportingFrequency.value,
  });
});
</script>

<template>
  <ui-modal v-model="modal" :title="title" width="500">
    <template v-if="reportingFrequency !== 'dateTime'">
      <ui-form-input-select v-if="reportingFrequency === 'week'" name="week" :items="getWeeksAsOptions" :label="t('global.week')" />
      <ui-form-input-select v-if="reportingFrequency === 'month'" name="month" :items="getMonthsAsOptions" :label="t('global.month')" />
    </template>
    <template v-else>
      <ui-form-input-date
        name="start"
        :type="reportingFrequency"
        :disabled-dates="[{ start: dayjs().add(1, 'day').toDate() }, { start: dayjs(values.end).add(1, 'day').toDate() }]"
        :label="t('global.start_date')"
      />
      <ui-form-input-date
        name="end"
        :type="reportingFrequency"
        :disabled-dates="[{ start: dayjs().add(1, 'day').toDate() }, { end: dayjs(values.start).subtract(1, 'day').toDate() }]"
        :label="t('global.end_date')"
      />
    </template>
    <ui-form-input-text name="quantity" placeholder="0" type="number" :right-text="unit" :label="t('global.quantity')" />

    <template #footer>
      <ui-button color="secondary" @click="modal = false">
        {{ t('global.cancel') }}
      </ui-button>
      <ui-button color="primary" :loading="isSubmitting" @click="submit">
        {{ t('global.save') }}
      </ui-button>
    </template>
  </ui-modal>
</template>
