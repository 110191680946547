<script setup lang="ts">
import type { ChartOptions } from 'chart.js';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useConsumption } from '../../composables/useConsumption';
import { useI18n } from 'vue-i18n';

dayjs.extend(weekOfYear);

// Composable
const { t } = useI18n();
const { proxy } = useScriptSegment();

// Composables
const { getConsumption, formatConsumptionForGraph } = useConsumption();
const { formatNumberToIsoNumber, formatNumberToIsoEuroNumber } = useNumbers();

// Data
const zoomStart = ref(0);
const zoomEnd = ref(0);

// Props and emits
const modal = defineModel<boolean>();
const props = defineProps<{
  meterId: number;
  period: { start: string; end: string };
  aggregationLevel: string;
}>();

const { loading, data } = useAsyncData({
  promise: () =>
    getConsumption({
      meterId: props.meterId,
      start: dayjs(props.period.start).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(props.period.end).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      aggregationLevel: 'minute',
    }),
  callback: () => {
    zoomStart.value = 0;
    zoomEnd.value = data.value.length - 1;
  },
  options: {
    immediate: true,
  },
});

// Methods
const handleZoom = (start: number, end: number) => {
  zoomStart.value = start;
  zoomEnd.value = end;
};

// Computed
const title = computed(() => {
  const start = dayjs(props.period.start);
  const end = dayjs(props.period.end);
  const meter = meterStore().getMeter(props.meterId);

  if (end.diff(start, 'days') <= 1) {
    return meter?.area.name + ' - ' + t('analytics.consumption of day', { date: start.locale('fr').format('dddd D MMMM YYYY') });
  } else if (props.aggregationLevel === 'week') {
    return meter?.area.name + ' - ' + t('analytics.consumption of week', { week: start.locale('fr').week() });
  } else if (props.aggregationLevel === 'month') {
    return meter?.area.name + ' - ' + t('analytics.consumption of month', { month: start.locale('fr').format('MMMM YYYY') });
  } else {
    return meter?.area.name + ' - ' + t('analytics.consumption of month', { month: start.locale('fr').format('MMMM YYYY') });
  }
});
const consumption = computed(() => formatConsumptionForGraph(data.value));

const totalConsumption = computed(() => {
  return formatNumberToIsoNumber(
    consumption.value.consumption_kwh.slice(zoomStart.value, zoomEnd.value).reduce((acc: number, curr: number) => acc + curr, 0),
  );
});

const totalCost = computed(() => {
  return formatNumberToIsoEuroNumber(
    consumption.value.cost.slice(zoomStart.value, zoomEnd.value).reduce((acc: number, curr: number) => acc + curr, 0),
  );
});

const averagePower = computed(() => {
  const average = formatNumberToIsoNumber(
    consumption.value.power_kw.slice(zoomStart.value, zoomEnd.value).reduce((acc: number, curr: number) => acc + curr, 0) /
      consumption.value.power_kw.slice(zoomStart.value, zoomEnd.value).length,
  );
  if (zoomStart.value === zoomEnd.value) {
    return 0;
  }
  return average;
});

const skipp = computed((): boolean => {
  if (props.aggregationLevel === 'day') {
    return true;
  }
  return false;
});

const options: ChartOptions = {
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: t('analytics.power_in_kw'),
        padding: 8,
      },
      grace: '5',
    },
    x: {
      title: {
        display: true,
        text: `${t('global.time')}`,
        padding: 8,
      },
      ticks: {
        autoSkip: skipp.value,
        callback: function (val: any) {
          const hours = dayjs(this.getLabelForValue(val)).format('HH:mm');
          switch (props.aggregationLevel) {
            case 'day':
              return dayjs(this.getLabelForValue(val)).format('HH:mm');
            case 'week':
              const validHours = ['00:00', '06:00', '12:00', '18:00'];
              return validHours.includes(hours) ? dayjs(this.getLabelForValue(val)).format('ddd HH:mm') : '';
            case 'month':
              const validHoursMonth = ['00:00'];
              return validHoursMonth.includes(hours) ? dayjs(this.getLabelForValue(val)).format('ddd DD HH:mm') : '';
          }
        },
      },
      grid: {
        display: false,
      },
    },
  },
  elements: {
    point: {
      pointStyle: false,
    },
    line: {
      borderWidth: 8,
    },
  },
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  plugins: {
    tooltip: {
      position: 'average',
      rtl: true,
      callbacks: {
        label: (context: any) => {
          const label = context.dataset.label || '';
          const value = context.parsed.y || 0;
          return `${label}: ${parseFloat(value).toFixed(2)} kW`;
        },
        title: (context: any) => {
          return dayjs(context[0].label)
            .locale('fr')
            .format(`dddd D MMMM YYYY ${t('global.at')} HH:mm`);
        },
      },
    },
  },
};

onMounted(() => {
  proxy.track('ConsumptionZoomModal');
});
</script>
<template>
  <ui-modal v-model="modal" :title="title" width="1100">
    <div v-if="loading" class="h-[300px] flex items-center justify-center">
      <ui-loader />
    </div>
    <template v-else>
      <div class="grid gap-4">
        <div class="bg-gray-50 p-4 rounded flex">
          <div>
            <p class="text-start">
              <span class="text-gray-400"> {{ $t('global.start') }}: </span>

              {{
                dayjs(consumption.timestamps[zoomStart])
                  .locale('fr')
                  .format(`dddd DD MMMM YYYY ${$t('global.at')} HH:mm`)
              }}
            </p>
            <p class="text-start">
              <span class="text-gray-400"> {{ $t('global.end') }}: </span>
              {{
                dayjs(consumption.timestamps[zoomEnd])
                  .locale('fr')
                  .format(`dddd DD MMMM YYYY ${$t('global.at')} HH:mm`)
              }}
            </p>
          </div>
          <div class="mx-8 bg-gray-400 w-[1px]" />
          <div>
            <p class="text-start">
              <span class="text-gray-400"> {{ $t('global.average_power') }}: </span>
              {{ averagePower }} kW
            </p>
            <p class="text-start">
              <span class="text-gray-400"> {{ $t('global.consumption') }}: </span>
              {{ totalConsumption }} kWh
            </p>
          </div>
          <div class="mx-8 bg-gray-400 w-[1px]" />
          <div>
            <p class="text-start">
              <span class="text-gray-400"> {{ $t('global.cost') }}: </span>
              {{ totalCost }}
            </p>
          </div>
        </div>
      </div>
      <ui-chart
        class="mt-2"
        type="line"
        :data="{
          labels: consumption.timestamps,
          datasets: [
            {
              label: $t('global.power'),
              data: consumption.power_kw,
              borderWidth: 1,
              borderColor: '#FA541C',
              backgroundColor: '#FFF2E8',
            },
          ],
        }"
        :options="options"
        :plugins="{ 'chartjs-plugin-zoom': true, legend: false }"
        @zoom="handleZoom"
      />
    </template>
    <template #footer>
      <ui-button color="secondary" @click="modal = false">Fermer</ui-button>
    </template>
  </ui-modal>
</template>
